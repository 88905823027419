import React, { useState } from 'react';
import axios from 'axios';
import '../../styles/styles.css'; // Import the CSS file
import Popup from '../../components/popup/Popup';
import { useNavigate, useParams} from 'react-router-dom';
import baseUrl from '../../config/config';
import Navbar from '../../components/navbar/Nabvar';
import api from '../../api/api';
import icons from '../../type/type';

const RegisterForm = () => {
  const { id } = useParams();
  const token = localStorage.getItem('token'); //retrive the token from the local storage

  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    deviceType: '',
    deviceName: '',
    ticketNumber: '',
    employeeId: id,
    deviceModel: '',
    operatingSystem: '',
    macAddress: '',
    validityPeriod: '',
    validityUnit: ''
  });
  const [popup, setPopup] = useState(null);
  const [deviceTypeId, setDeviceTypeId] = useState(null);
  const [validityPeriod, setValidityPeriod] = useState('');
  const [validityUnit, setValidityUnit] = useState('Day');

  const handleValidityPeriodChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    const maxLimit = getMaxLimit(validityUnit);

    if (value > maxLimit) {
        value = maxLimit;
    }
    
    setValidityPeriod(value);
    setFormData({ ...formData, [name]: value });
  };

  const handleValidityUnitChange = (e) => {
      const newUnit = e.target.value;
      const name = e.target.name;
      setValidityUnit(newUnit);
      const maxLimit = getMaxLimit(newUnit);
      
      if (validityPeriod > maxLimit) {
          setValidityPeriod(maxLimit);
      }
      
    setFormData({ ...formData, [name]: newUnit });
  };

  const getMaxLimit = (unit) => {
      switch(unit) {
          case 'Day':
              return 90;
          case 'Week':
              return 12;
          case 'Month':
              return 3;
          default:
              return 90;
      }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'deviceType'){
      setDeviceTypeId(value);
      setFormData({ ...formData, [name]: Number(value) });
    } else{
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'deviceType',
      'deviceName',
      'ticketNumber',
      'deviceModel',
      'operatingSystem',
      'macAddress',
      'validityPeriod',
      'validityUnit'
    ];

    const emptyFields = requiredFields.filter(field => !formData[field] || (typeof formData[field] === 'string' && !formData[field].trim()));
    if (emptyFields.length > 0) {
      setPopup({ type: 'error', message: (emptyFields.length === 1 ? emptyFields+' field must be filled out': emptyFields +' fields must be filled out' )});
      return;
    }

    try {
      if(!token){
        navigate("/login")
      }else{
        console.log(token);
        const res = await api.post(`${baseUrl}/api/device-register`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        //alert(res.data);//
        setPopup({ type: 'success', message: res.data });
        setFormData({
          deviceType:'',
          deviceName: '',
          ticketNumber:'',
          employeeId: id,
          deviceModel: '',
          operatingSystem: '',
          macAddress: '',
          validityPeriod: '',
          validityUnit: ''
        });
        if (res.data === 'Already Registerd With This employee id') {
          // User found, navigate to the status page
          setPopup({ type: 'error', message: 'Already Registered The Device' });
        } else {
          //alert(res.data);//
          setPopup({ type: 'success', message: res.data });
          navigate(`/status/${id}`);
        }
    }
    } catch (error) {
      console.error('Error registering device:', error);
      if (error.response && error.response.data) {
        alert("error")
        // alert(error.response.data)
        setPopup({ type: 'error', message: "error on uploading data"});
      } else {
        setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
      }
    }
  };

  const handleLoginClick = () =>{
    navigate("/login")
  }

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className="content-section-main">

    <div className='content-section-inner'>
      <form className="register-form" onSubmit={handleSubmit}>
        <div className='form-heading-main'>
          <div className='form-head'><h2>Register Device</h2></div>
          {/* <div className='form-sub-head'><p>Please register your personal device here and wait for the approval</p></div> */}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="deviceType">Device Type:</label>
            <select name="deviceType" onChange={handleChange} value={deviceTypeId}>
              <option value="">Please select..</option>
              {icons.map((option) => {
                  return (
                      <option key={option.value} value={option.value}>
                          {option.label}
                      </option>
                  );
              })}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="ticketNumber">Ticket Number:</label>
            <input type="text" id="ticketNumber" name="ticketNumber" value={formData.ticketNumber} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="deviceModel">Status:</label>
            <div className='active_container' style={{ color: "#eea121", backgroundColor: "#fcf3d7"}}>
                Pending
            </div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="deviceName">Device Name:</label>
            <input type="text" id="deviceName" name="deviceName" value={formData.deviceName} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="deviceModel">Device Model:</label>
            <input type="text" id="deviceModel" name="deviceModel" value={formData.deviceModel} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="operatingSystem">Operating System:</label>
            <input type="text" id="operatingSystem" name="operatingSystem" value={formData.operatingSystem} onChange={handleChange} />
          </div>
        </div>

        <div className="form-row">

          <div className="form-group">
            <label htmlFor="macAddress">Mac Address:</label>
            <input type="text" id="macAddress" name="macAddress" value={formData.macAddress} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="macAddress">Validity Period:</label>
            <div className='validity-section'>
              <input type="number" name="validityPeriod" value={validityPeriod} onChange={handleValidityPeriodChange}min="1" max={getMaxLimit(validityUnit)} />
              <select name="validityUnit" value={validityUnit} onChange={handleValidityUnitChange}>
                <option value="Day">Day(s)</option>
                <option value="Week">Week(s)</option>
                <option value="Month">Month(s)</option>
              </select>
            </div>
          </div>
            
        </div>
        <div className="form-row">
                <span className="bg-red">* All fields are requierd</span>
        </div>

        <div className='button-section'>
          <button className='button' type="submit">Register</button>
        </div>
      </form>

      {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
        </div>
        </div>

  );
};

export default RegisterForm;
 