import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router";
import "./App.css";
import RegisterForm from "./pages/registerFormPage/RegisterForm";
import StatusPage from "./pages/statusPage/StatusPage";
import Login from "./pages/loginPage/Login";
import Update from "./pages/updatePage/Update";
import { useEffect, useState } from "react";
import ProtectedRoute from "./routes/ProtectedRoutes";
import Navbar from "./components/navbar/Nabvar";
import Sidebar from "./components/sidebar/Sidebar";
import AllDevices from "./pages/AllDevicesPage/AllDevices";
import Home from "./pages/HomePage/Home";
import DeviceDetails from "./pages/deviceDetailsPage/DeviceDetails";
import MaintenanceMode from "./pages/MaintenanceMode/MaintenanceMode";
import { corporateUrl } from '../src/config/config';
import api from '../src/api/api';

function App() {
  const [authenticated, setAuthenticated] = useState(

    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication

  );
  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('authenticated',authenticated);
  },[authenticated])
  const [activeIndex,setActiveIndex] = useState(1);

  // Maintenance check on load/refresh
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const tool = 'personalasset';
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.warn('Access token is missing, skipping maintenance check.');
        return;
      }

      try {
        const response = await api.get(`${corporateUrl}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        
        const isMaintenanceActive = response.data.maintenanceStatus || false;
        setMaintenanceStatus(isMaintenanceActive);

        if (isMaintenanceActive && !location.pathname.includes("/maintenance-mode")) {
          navigate(`/maintenance-mode/${tool}`);
        }
      } catch (error) {
        console.error("Failed to fetch maintenance details:", error);
        setMaintenanceStatus(false);
      }
    };

    fetchMaintenanceDetails();
  }, [navigate, location.pathname]);

  // On maintenance end, redirect to home page if refreshed or on maintenance page
  useEffect(() => {
    // Extract the toolName from the URL path
    const toolName = location.pathname.split("/maintenance-mode/")[1];

    if (!maintenanceStatus && location.pathname.includes("/maintenance-mode")) {
      // If toolName is "corporateportal", redirect to home or login
      if (toolName === 'personalasset') {
        const userId = localStorage.getItem("user_id");
        if (userId) {
          navigate(`/status/${userId}`);
        } else {
          navigate("/login");
        }
      }
      // Otherwise, do not redirect, allow for other tools
    }
  }, [maintenanceStatus, location.pathname, navigate]);

  return (
    <div className="App">
      <div className="main-content">
        <div className="body-section">
          <div className="body-inner">
            {!location.pathname.includes("/maintenance-mode") && (
              <Navbar />
            )}
            {authenticated &&  !location.pathname.includes("/maintenance-mode") && ( 
              <div className="sidebar-section min-vh-0">
                <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/> 
              </div>
            )}
            

            <Routes>
              <Route path="/register/:id" element={<RegisterForm />} />
              <Route path="/status/:id" element={<StatusPage />} />
              <Route path="/all-devices" element={<AllDevices />} />
              <Route path="/update/:id/:objId" element={<Update />} />
              <Route path="device-details/:objId/:empId" element={<DeviceDetails/>} />
              <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
              />
              <Route path="/" element={<Home/>} />
              
              <Route path="/maintenance-mode/:tool" element={<MaintenanceMode />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
